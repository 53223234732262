import React from 'react'
import "./menu.scss"

export default function Menu({menuOpen, setMenuOpen}) {
    
    return (
        <div className={"menu " + (menuOpen && "active")}>
           <ul>
               <li onClick={()=>setMenuOpen(false)}>
                   <a href="#intro">Home</a>
               </li>
                <li onClick={()=>setMenuOpen(false)}>
                   <a href="#aboutme">About Me</a>
               </li>
               <li onClick={()=>setMenuOpen(false)}>
                   <a href="#works">My Projects</a>
               </li>
                <li onClick={()=>setMenuOpen(false)}>
                   <a href="#contact">Contact Me</a>
               </li>
            </ul> 
        </div>
    )
}
