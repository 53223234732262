import React from 'react'
import {useState} from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import GitHubIcon from '@mui/icons-material/GitHub';
import "./works.scss"

export default function Works() {
    const [currentSlide, setCurrentSlide] = useState(0);

    const data = [
    {
        id: 1,
        name: "jpaint",
        icon: "./assets/brush.png",
        title: "JPaint",
        github: "https://github.com/david-vest/jpaint",
        desc: 
            `JPaint is a simple Java graphics creation program created to learn Object-oriented programming and design patterns. 
            It can create, move, select, copy, paste, and delete shapes on a canvas.`,
        img: "./assets/jpaint.png"
    },
  {
        id: 2,
        name: "pichores",
        icon: "./assets/raspberry_pi.png",
        title: "PiChores",
        github: "https://github.com/david-vest/pichores",
        desc: 
            "PiChores is a Python program using a Raspberry Pi Zero W that displays whose turn it is for each chore using an OLED screen and messages the user when chores need to be done.",
        img: "./assets/pichores.png"
    },
  {
        id: 3,
        name: "robot",
        icon: "./assets/robot.png",
        title: "FRC 2019 Robot",
        github: "https://github.com/FRCTeam1987/Robot2019",
        desc: 
            "I participated in the FIRST Robotics Competition for 4 years in high school. I was the lead programmer in 2019 and we received the first Autonomous Award in our team's history.",
        img: "./assets/frc_robot.jpg"
    },]

    const handleClick = (way)=>{
        way === "left" ? setCurrentSlide(currentSlide > 0 ? currentSlide-1 : data.length-1) : 
        setCurrentSlide(currentSlide < data.length - 1 ? currentSlide + 1 : 0);
    };

    return (
        <div className="works" id="works">
            <h1>Projects</h1>
            <div className="slider" 
            style={{ transform:  `translateX(-${currentSlide * 100}vw)` }}
            >
                {data.map(d=>(
                <div className="container">
                    <div className="item">
                        <div className="left">
                            <div className="leftContainer">
                                <div className="imgContainer">
                                    <img src={d.icon} alt="" />
                                </div>
                                <h2>{d.title}</h2>
                                <p>{d.desc}</p>
                                <a href={d.github} target="_blank" rel="noopener noreferrer"><GitHubIcon className="icon"/></a>
                                </div>
                        </div>

                        <div className="right">
                            <img src={d.img} alt="" className={d.name}/>
                        </div>
                    </div>
                </div>
                ))};
            </div>
            <ArrowForwardIosIcon className="arrow left" onClick={()=>handleClick("left")}/>
            <ArrowForwardIosIcon className="arrow right" onClick={()=>handleClick("right")}/>
        </div>
    )
}
