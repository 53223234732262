import React from 'react';
import "./topbar.scss";
import EmailIcon from '@mui/icons-material/Email';
import GitHubIcon from '@mui/icons-material/GitHub';


export default function Topbar({menuOpen, setMenuOpen}) {
    return (
        <div className={"topbar " + (menuOpen && "active")}>
            <div className="wrapper">
                <div className="left">
                    <a href="#intro" className="logo">david_vest</a>
                    <div className="itemContainer">
                        <EmailIcon className="icon"/>
                        <span>dvest677@gmail.com</span>
                    </div>
                    <div className="itemContainer">
                        <GitHubIcon className="icon"/>
                        <span><a href="https://github.com/david-vest">david-vest</a></span>
                    </div>    
                </div>
                <div className="right">
                    <div className="hamburger" onClick={()=>setMenuOpen(!menuOpen)}>
                        <span className="line1"></span>
                        <span className="line2"></span>
                        <span className="line3"></span>
                    </div>
                </div>
            </div>
        </div>
    )
}
