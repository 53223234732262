import React from 'react'
import {useState, useRef} from 'react'
import "./contact.scss"
import emailjs from 'emailjs-com';


export default function Contact() {
    const [message, setMessage] = useState(false);
    const formRef = useRef();

    const handleSubmit = (e)=>{
        e.preventDefault();
        emailjs.sendForm(
            process.env.SERVICE_ID,
            process.env.TEMPLATE_ID,
            formRef.current,
            process.env.USER_ID
        )
        .then(
            (result)=> {
                console.log(result.text);
                setMessage(true);
            },
            (error)=> {
                console.log(error.text)
            });
    };


    return (
        <div className="contact" id="contact">
            <h2>Contact</h2>
            <form ref={formRef} onSubmit={handleSubmit}>
                <input type ="name" name="user_name" placeholder="Name"/>
                <input type="email" name="user_email" placeholder="Email" />
                <textarea placeholder="Message" name ="message"></textarea>
                <button type="submit" value="Send">Send</button>
                {message && <span>Thanks, I will reply as soon as possible!</span>}
            </form>
        </div>
    )
}
