import React from 'react'
import DescriptionIcon from '@mui/icons-material/Description';
import "./aboutme.scss"

export default function AboutMe() {

    return (
        <div className="aboutme" id="aboutme">
           <h1>About Me</h1> 
           <div className="container">
               <div className="card">               
                    <div className="left">
                        <div className="leftContainer">
                        <h2>My name is <span>David Vest</span></h2>
                        <p>I'm a junior at DePaul University studying <span>computer science</span> (concentration in software development) and minoring <br/>in <span>sound design.</span>
                        I started programming when I was 13 years old by creating Minecraft mods using Java and using<br/>
                        the Minecraft Forge framework. I then joined my high schools FIRST robotics team to apply my programming skills <br/>
                        to a cyberphysical medium. Now I work at <span>DePaul's Genius Squad</span>, which is the university's free tech support <br/>
                        for students, faculty, and staff. 

                        <br/>
                        <br/>
                        With these experiences and my formal education at DePaul, I have become a avid problem solver, good communicator, and <br/>
                        a solid developer.
                        </p>

                        <div className="resume">
                            <a download="resume.pdf" href="/assets/resume.pdf">
                                <DescriptionIcon className="resumeIcon"/>
                            </a>
                            <div className="resumeText">
                                Click to download resume
                            </div>
                        </div>
                        </div>

                    </div>
                    <div className="right">
                        <div className="imgContainer">
                            <img src="/assets/me2.jpg" alt="" className="user"/>
                        </div>
                    </div>
               </div>
           </div>
        </div>
    )
}
