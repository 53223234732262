import React from 'react'
import {useEffect, useRef} from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {init} from 'ityped'
import "./intro.scss"

export default function Intro() {

    const textRef = useRef();

    useEffect(() => {
       init(textRef.current, {
           showCursor: true,
           backDelay: 1500,
           disableBackTyping: false,
           loop: true,
           cursorChar: '_',
           strings: ["n Entry Level Software Developer", " Java Programmer", "n OOP Developer", " Python Programmer",
           " Sound Designer", " Video Editor",  " Genius Squad Technician"]
       });
    }, []);

    return (
        <div className="intro" id="intro">
            <div className="left">
                <div className="imgContainer">
                    <img src="assets/me.jpg" alt="" />
                </div>
            </div>
            <div className="right">
                <div className="wrapper">
                    <h2>Hello There, I'm</h2> 
                    <h1>David Vest</h1>
                    <h3>I'm a<span ref={textRef}></span></h3>
                </div>
                <a href="#aboutme">
                    <ExpandMoreIcon className="icon" sx={{fontSize: 50}}/>
                </a>
            </div>
        </div>
    );
}
